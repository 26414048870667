/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate {
    padding: 10px !important;
}


@page {
    size: A5 landscape;
}

@page :left {
    margin-left: 3cm;
}

@page :right {
    margin-left: 4cm;
}
  